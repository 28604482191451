@import "variables.css";
@import "../../../node_modules/@coreui/coreui/dist/css/coreui.min.css";
@import "custom.css";
@font-face {
  font-family: "usatnma-font";
  src: local("usatnma"),
    url(/fonts//OpenSans-VariableFont_wdth\,wght.ttf) format("truetype");
}
body {
  font-family: usatnma-font;
}
body nav {
  font-weight: 500;
}
.c-app {
  min-height: 50vh;
}
.c-main {
  padding-top: 0;
}
.post-page {
  font-size: 15px;
}
.post-page a {
  color: var(--base-color);
}
.post-page .post-page-head {
  padding-top: 15px;
}
.page-background {
  background-color: azure;
  padding: 2rem;
}
.btn-success,
.btn-primary {
  background-color: var(--base-color);
  border-color: var(--base-color);
}
.bg-primary {
  background-color: #155799 !important;
}
